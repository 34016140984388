import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { styled } from './../../../theme/index';
import Heading from './../../atoms/Heading/Heading';
import CursiveLink from './../../atoms/CursiveLink/CursiveLink';
import BaseImage from 'src/components/atoms/BaseImage/BaseImage';
import Text from './../../atoms/Text/Text';
import moment from 'moment';

export interface Props {
  type: string;
  title: string;
  image: string;
  date?: string;
  nid?: number;
  content: string;
  link: string;
  announcements?: boolean;
  featured?: boolean;
  homePost?: boolean;
}

const Container = styled('div', {
  boxShadow:
    '0px 1px 5px hsla(0, 0%, 0%, 0.15), 0px 3px 3.4px -0.8px hsla(0, 0%, 0%, 0.15), 0px 7.3px 8.2px -1.7px hsla(0, 0%, 0%, 0.15), 0px 17.5px 19.7px -2.5px hsla(0, 0%, 0%, 0.15)',
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  variants: {
    featured: {
      true: {
        boxShadow: 'none',
        '@bp3': {
          boxShadow:
            '0px 1px 5px hsla(0, 0%, 0%, 0.15), 0px 3px 3.4px -0.8px hsla(0, 0%, 0%, 0.15), 0px 7.3px 8.2px -1.7px hsla(0, 0%, 0%, 0.15), 0px 17.5px 19.7px -2.5px hsla(0, 0%, 0%, 0.15)',
          display: 'grid',
          gridTemplateColumns: '33.33% 1fr',
          columnGap: '40px',
        },
      },
    },
    homePost: {
      true: {
        maxWidth: '706px',
        '@bp3': {
          width: '100%',
          maxWidth: '458px',
        },
      },
    },
    announcements: {
      true: {
        width: '100%',
        maxWidth: '706px',
        '@bp3': {
          width: '100%',
          maxWidth: '474px',
        },
      },
    },
  },
});

const Top = styled('div', {
  minHeight: '75px',
  fontSize: '18px',
  fontFamily: '$quote',
  lineHeight: 1.44,
  backgroundColor: '#F8F8F8',
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
});

const Details = styled('div', {
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 2,
  variants: {
    featured: {
      true: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        padding: '40px 40px 40px 0',
      },
    },
    homePost: {
      true: {
        maxWidth: '706px',
        '@bp3': {
          width: '100%',
          maxWidth: '458px',
        },
      },
    },
    announcements: {
      true: {
        width: '100%',
        maxWidth: '706px',
        paddingBottom: '0',
        '@bp3': {
          width: '100%',
          maxWidth: '474px',
        },
      },
    },
  },
});

const Icon = styled('span', {
  fontSize: '14px',
  paddingLeft: '6px',
});

const Bottom = styled('div', {
  justifySelf: 'end',
  alignSelf: 'end',
  fontSize: '20px',
  variants: {
    featured: {
      true: {
        marginTop: '8px',
        '@bp3': {
          marginTop: '18px',
        },
      },
    },
  },
});

const blogTitleStyles = {
  fontSize: '$size18',
  fontFamily: '$quote',
  letterSpacing: '0.75px',
  fontWeight: '700',
  marginBottom: '8px',
  textTransform: 'none',
};

const featuredTitleStyles = {
  fontSize: '19px',
  lineHeight: 1,
  fontFamily: '$quote',
  letterSpacings: '0.24px',
  fontWeight: '700',
  margin: '0 0 8px',
  textTransform: 'none',
  '@bp3': {
    fontSize: '21px',
    lineHeight: 1.18,
  },
};

const textStyles = {
  margin: '0 0 24px',
  fontSize: '15px',
  lineHeight: 1.46,
  wordWrap: 'break-word',
};

const dateStyles = {
  margin: '0 0 8px',
  color: '#888',
  fontSize: '12px',
  lineHeight: 1.357,
  fontFamily: '$sourceSans',
};

const FeatureTag = styled('div', {
  position: 'absolute',
  right: '16px',
  top: '16px',
  background: '$black',
  color: '$clear',
  fontFamily: '$quote',
  fontSize: '17px',
  lineHeight: 0.9,
  fontWeight: '700',
  letterSpacing: '0.75px',
  padding: '14px 22px',
  boxShadow:
    '0px 1px 1.1px rgba(0, 0, 0, 0.3), 0px 1.5px 1.7px -1.2px rgba(0, 0, 0, 0.3), 0px 3.5px 3.9px -2.5px rgba(0, 0, 0, 0.3)',
  zIndex: 1,
});

const BlogSnippet: React.FC<Props> = ({
  type,
  title,
  image,
  nid,
  date,
  content,
  link,
  announcements = false,
  featured = false,
  homePost = false,
}) => {
  let dateFormat = '';

  if (typeof date === 'string') {
    dateFormat = moment.unix(parseInt(date)).format('MMMM DD,YYYY');
  }

  let blogLink = '';
  if (type === 'featuredAnnouncements') {
    blogLink = link;
    if (!link.includes('http://') && !link.includes('https://')) {
      blogLink = '#';
    }
  } else {
    blogLink = `/blog-post/${nid}`;
  }

  return (
    <Container
      featured={featured}
      announcements={announcements}
      homePost={homePost}
    >
      {announcements && (
        <Top>
          <Heading
            css={{
              latterSpacing: '0.2px',
              textTransform: 'none',
              fontWeight: 'normal',
            }}
            as="h4"
          >
            <Link to={blogLink}>{title}</Link>
          </Heading>
        </Top>
      )}
      <div
        style={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: '#666',
        }}
      >
        {featured && <FeatureTag>Featured</FeatureTag>}

        <Link
          to={blogLink}
          style={{ display: 'block', width: '100%' }}
        >
          <BaseImage
            src={image}
            alt={'Blog image'}
            width={400}
            height={400}
            objectFit={'cover'}
            lazy={true}
          />
        </Link>
      </div>
      <Details
        announcements={announcements}
        featured={featured}
      >
        <div>
          {!announcements && (
            <>
              {date !== null && <Text css={dateStyles}>{dateFormat}</Text>}
              <Heading
                css={featured ? featuredTitleStyles : blogTitleStyles}
                as="h2"
              >
                <Link to={blogLink}>{title}</Link>
              </Heading>
            </>
          )}
          <Text
            css={textStyles}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
        {!announcements && (
          <Bottom featured={featured}>
            <CursiveLink
              css={{
                fontSize: '24px',
                lineHeight: '14px',
              }}
              color="green"
              href={blogLink}
              underline
            >
              Read More
              <Icon>
                <FontAwesomeIcon icon={faArrowRight} />
              </Icon>
            </CursiveLink>
          </Bottom>
        )}
      </Details>
    </Container>
  );
};

export default BlogSnippet;
