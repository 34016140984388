// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { styled, type ThemeProps } from './../../../theme/index';
import './layout.scss';
import DashboardNavigation from './../../molecules/DashboardNavigation/DashboardNavigation';
import SideDashboard from './../../molecules/SideDashboard/SideDashboard';
import Footer from './../../organisms/Footer/Footer';
import Heading from './../../atoms/Heading/Heading';
import { footerMenuLinks } from '../../../theme/menuLinks';
import { PopupProvider } from './../../../state/PopupProvider';
import { StylesTag } from './../../../theme/global';
import { BeamerHelmet } from 'react-beamer';

export interface Props extends ThemeProps {
  title?: string;
  noBottomSpace?: boolean;
  responsive?: boolean;
}

const Container = styled('div', {
  paddingBottom: 0,
  paddingTop: 0,
});

const Grid = styled('div', {
  position: 'relative',
  minHeight: 'calc(100vh - 170px)',
  '@bp3': {
    minHeight: 'calc(100vh - 73px)',
    display: 'grid',
    gridTemplateColumns: '260px calc(100% - 260px)',
  },
});

const Content = styled('div', {
  padding: '14px 19px',
  '@bp3': {
    padding: '32px 32px 16px',
  },
});

const Wrapper = styled('div', {
  position: 'relative',
  paddingBottom: '80px',
  maxWidth: '744px',
  margin: 'auto',
  '@bp3': {
    margin: 0,
    maxWidth: '100%',
  },
  variants: {
    noBottomSpace: {
      true: {
        paddingBottom: 0,
      },
    },
  },
});

const Title = styled(Heading, {
  color: '$darkgray',
  fontFamily: '"Josefin Sans",sans-serif !important',
  fontSize: '25px',
  fontWeight: 'bold',
  letterSpacing: '1px',
  lineHeight: '25px',
  textTransform: 'uppercase',
  padding: '34px 36px 0',
  variants: {
    responsive: {
      true: {
        fontSize: '20px',
        '@bp4': {
          fontSize: '25px',
        },
      },
    },
  },
});

const initMenus = {
  top: [],
  side: [],
  footer: [],
};

let beamerData = {};
if (window.localStorage.getItem('user_credit') !== null) {
  const userCred = JSON.parse(window.localStorage.getItem('user_credit'));
  beamerData = {
    user_id: userCred.username,
    user_email: userCred.user.email,
  };
}

const Layout: React.FC<Props> = ({
  menusData = null,
  title,
  children,
  noBottomSpace,
  responsive,
  forgotMenu = null,
  bmk = false,
  ...props
}) => {
  const { userCredentials } = useContext(UserContext);
  const [topMenu, setTopMenu] = useState([]);
  const [sideMenu, setSideMenu] = useState([]);
  const [mobileSecondaryMenu, setMobileSecondaryMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState(footerMenuLinks);
  const [isShowTopBottom, setIsShowTopBottom] = useState(true); // showing top/btm menus flag

  const { user } = userCredentials;

  useEffect(() => {
    let menus = initMenus; // set empty menus as default
    const pageURL = window.location.href;

    if (pageURL.includes('/bmk')) {
      setIsShowTopBottom(false);
    }

    if (forgotMenu !== null) {
      menus = forgotMenu;
    } else if (menusData !== null) {
      window.localStorage.setItem('menus', JSON.stringify(menusData));
      menus = menusData;
    } else if (window.localStorage.getItem('menus') !== null) {
      menus = JSON.parse(window.localStorage.getItem('menus'));
    }

    setTopMenu(menus.top);
    setSideMenu(menus.side);
    setFooterMenu(menus.footer);
    setMobileSecondaryMenu(menus.mobilesecondary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menusData]);

  return (
    <StylesTag>
      <BeamerHelmet
        id={'ZXIqYvLJ27700'}
        args={beamerData}
      />

      <Container
        className="layout__container"
        {...props}
      >
        <PopupProvider code={user.uid}>
          <Grid className="layout__grid">
            <SideDashboard
              className="layout__sideDashBoard"
              items={sideMenu}
              responsiveItems={mobileSecondaryMenu}
              bmk={bmk}
            />{' '}
            {/* responsiveItems={sideDashboardResponsiveLinks} */}
            <Wrapper
              className="layout__wrapper"
              noBottomSpace={noBottomSpace}
            >
              {isShowTopBottom ? <DashboardNavigation items={topMenu} /> : null}

              {title !== undefined && (
                <Title responsive={responsive}>{title}</Title>
              )}
              <Content className="layout__layout">{children}</Content>
            </Wrapper>
          </Grid>
        </PopupProvider>
        {isShowTopBottom ? <Footer userFooterMenu={footerMenu} /> : null}
      </Container>
    </StylesTag>
  );
};

export default Layout;
