import React from 'react';

const Icon = (): JSX.Element => {
  return (
    <svg
      id="Icon-Twitter"
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="currentColor"
      preserveAspectRatio="xMinYMin meet"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8054 16.9373L38.6963 0H35.1677L22.2379 14.7064L11.9109 0H0L15.6164 22.2387L0 40H3.52887L17.183 24.4696L28.0891 40H40L23.8046 16.9373H23.8054ZM18.9722 22.4346L17.3899 20.2201L4.80037 2.59936H10.2205L20.3804 16.8199L21.9627 19.0344L35.1693 37.5189H29.7492L18.9722 22.4354V22.4346Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
