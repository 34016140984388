import React from 'react';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconChevron } from 'src/icons/iconChevron.svg';
import { ReactComponent as IconDoubleChevron } from 'src/icons/iconDoubleChevron.svg';
import { ReactComponent as IconDots } from 'src/icons/iconThreeDots.svg';
import { PaginationProps } from 'src/types/baseComponentTypes';
import usePagination from 'src/hook/usePagination/usePagination';
import 'src/components/molecules/Pagination/pagination.scss';

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setCurrentPage,
  totalCount,
  pageSize,
  maxVisiblePages = 7,
  hidePageNumbers = false,
}) => {
  const [paginationRange, totalPageCount] = usePagination({
    currentPage,
    totalCount,
    pageSize,
    maxVisiblePages,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onPageChange = (page: number) => {
    if (page === currentPage) return;
    setCurrentPage(page);
  };

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = totalPageCount;

  return (
    <nav
      className="pagination"
      aria-label="Pagination"
    >
      <button
        className="pagination__item pagination__item--prev"
        type="button"
        onClick={() => onPageChange(1)}
        aria-label="First Page"
        disabled={currentPage === 1}
      >
        <BaseIcon icon={IconDoubleChevron} />
      </button>
      <button
        className="pagination__item pagination__item--prev"
        type="button"
        onClick={onPrevious}
        aria-label="Previous Page"
        disabled={currentPage === 1}
      >
        <BaseIcon icon={IconChevron} />
      </button>

      {!hidePageNumbers &&
        paginationRange.map((pageNumber, index) => {
          if (pageNumber === 'dots') {
            return (
              <span
                key={`dots-${index}`}
                className="pagination__item pagination__item--dots"
              >
                <BaseIcon icon={IconDots} />
              </span>
            );
          }

          return (
            <button
              key={pageNumber}
              className={[
                'pagination__item',
                pageNumber === currentPage ? 'pagination__item--current' : '',
              ]
                .filter(Boolean)
                .join(' ')}
              type="button"
              onClick={() => onPageChange(pageNumber as number)}
              {...(pageNumber === currentPage
                ? { 'aria-current': 'page' }
                : {})}
            >
              {pageNumber}
            </button>
          );
        })}

      <button
        className="pagination__item pagination__item--next"
        type="button"
        onClick={onNext}
        aria-label="Next Page"
        disabled={currentPage === lastPage}
      >
        <BaseIcon icon={IconChevron} />
      </button>
      <button
        className="pagination__item pagination__item--next"
        type="button"
        onClick={() => onPageChange(lastPage)}
        aria-label="Last Page"
        disabled={currentPage === lastPage}
      >
        <BaseIcon icon={IconDoubleChevron} />
      </button>
    </nav>
  );
};

export default Pagination;
