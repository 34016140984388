import React, { useState } from 'react';
import { BlogSliderProps } from 'src/types/blogTypes';
import BlogSnippet from 'src/components/molecules/BlogSnippet/BlogSnippet';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import 'src/components/molecules/BlogSlider/blogSlider.scss';

const BlogSlider: React.FC<BlogSliderProps> = ({ collection }) => {
  const [index, setIndex] = useState(0);

  const handlePageChange = (selectedPage: number) => {
    setIndex(selectedPage);
  };

  return (
    <div className="blogSlider">
      <BlogSnippet
        featured={true}
        {...collection[index]}
      />

      <Pagination
        totalCount={collection.length}
        pageSize={1}
        currentPage={index + 1}
        setCurrentPage={(page) => handlePageChange(page - 1)}
        hidePageNumbers={true}
      />
    </div>
  );
};

export default BlogSlider;
