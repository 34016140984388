/**
 * Format currency values
 * - Adds commas for thousands
 * - Keeps exact decimals (no rounding)
 * - Removes .00 only for whole numbers
 * - Handles both positive and negative numbers
 */
export const formatCurrency = (value: number): string => {
  const fixedString = value.toFixed(2);
  const [whole, decimal] = fixedString.split('.');
  const formattedWhole = parseInt(whole).toLocaleString('en-US');

  if (decimal === '00') {
    return formattedWhole;
  }

  return `${formattedWhole}.${decimal}`;
};

/**
 * Format number values with commas
 */
export const formatNumber = (value: number): string => {
  return value.toLocaleString('en-US');
};
