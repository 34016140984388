import React from 'react';
import { Link } from 'react-router-dom';
import type { ErrorMessageProps } from 'src/types/validationTypes';
import 'src/components/atoms/ErrorMessage/errorMessage.scss';

const defaultErrorMessage: React.JSX.Element = (
  <span>
    Error: Oops, something went wrong, please try again later or{' '}
    <Link to="/contact-support">contact support</Link>
  </span>
);

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message = defaultErrorMessage,
  className = '',
}) => {
  return (
    <p
      className={`errorMessage ${className}`.trim()}
      aria-live="polite"
      role="alert"
    >
      {message}
    </p>
  );
};

export default ErrorMessage;
