const setFavicon = () => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.setAttribute('sizes', '32x32');
  link.href =
    process.env.NODE_ENV === 'production'
      ? '/favicon.ico?v=1.0'
      : '/favicon-dev.ico?v=1.0';
  document.head.appendChild(link);

  const svgLink = document.createElement('link');
  svgLink.rel = 'icon';
  svgLink.type = 'image/svg+xml';
  svgLink.href =
    process.env.NODE_ENV === 'production'
      ? '/icon.svg?v=1.0'
      : '/icon-dev.svg?v=1.0';
  document.head.appendChild(svgLink);

  const appleTouchIcon = document.createElement('link');
  appleTouchIcon.rel = 'apple-touch-icon';
  appleTouchIcon.href = '/apple-touch-icon.png?v=1.0';
  document.head.appendChild(appleTouchIcon);
};

export default setFavicon;
