import React from 'react';
import { TopBarProps } from 'src/types/navigationTypes';
import SocialItems from 'src/components/molecules/SocialItems/SocialItems';
import CursiveLink from 'src/components/atoms/CursiveLink/CursiveLink';
import 'src/components/molecules/TopBar/topBar.scss';

const TopBar: React.FC<TopBarProps> = ({ isScroll = false, ...props }) => {
  return (
    <div
      className={`topBar ${isScroll ? 'topBar--scroll' : ''}`.trim()}
      {...props}
    >
      <div className="topBar__inner">
        <SocialItems
          className="topBar__innerSocials"
          type="navbar"
          fontAwesomeIcon={true}
        />

        <div className="topBar__innerLinks">
          <CursiveLink
            className="topBar__innerLinksApply"
            href="https://www.brandcycle.com/apply-now"
          >
            apply now
          </CursiveLink>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
