import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';

interface GlobalProviderProps {
  children: ReactNode;
}

interface BmkInfoType {
  id: string;
  name: string;
}

interface GlobalVariables {
  auth: boolean;
  bmkInfo: BmkInfoType;
}

// sets initial values for user credentials
const initialGlobalVariables: GlobalVariables = {
  auth: false,
  bmkInfo: {
    id: '',
    name: '',
  },
};

interface GlobalContextType {
  globalVariables: GlobalVariables;
  updateGlobalVariables: (newGlobals: Partial<GlobalVariables>) => void;
}

// creates the GlobalContext
export const GlobalContext = createContext<GlobalContextType>({
  globalVariables: initialGlobalVariables,
  updateGlobalVariables: () => {
    console.warn('updateGlobalVariables method is not implemented');
  },
});

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [globalVariables, setGlobalVariables] = useState(
    initialGlobalVariables,
  );

  const updateGlobalVariables = useCallback(
    (newGlobals: Partial<GlobalVariables>): void => {
      try {
        const updatedGlobals = { ...globalVariables, ...newGlobals };
        window.localStorage.setItem('globals', JSON.stringify(updatedGlobals));
        setGlobalVariables(updatedGlobals);
      } catch (error) {
        console.error('Failed to update global variables:', error);
      }
    },
    [globalVariables],
  );

  useEffect(() => {
    try {
      const storedGlobals = window.localStorage.getItem('globals');
      if (storedGlobals) {
        const parsedGlobals = JSON.parse(storedGlobals);
        setGlobalVariables((prevGlobals) => ({
          ...prevGlobals,
          ...parsedGlobals,
        }));
      }
    } catch (error) {
      console.error(
        'Failed to load global variables from local storage:',
        error,
      );
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ globalVariables, updateGlobalVariables }}>
      {children}
    </GlobalContext.Provider>
  );
};
