import React, { useState, useContext, useEffect } from 'react';
import { BlogProps, BlogSnippetProps } from 'src/types/blogTypes';
import client from 'src/components/api';
import { UserContext } from 'src/context/UserContext';
import BlogList from 'src/components/molecules/BlogList/BlogList';
import BlogSlider from 'src/components/molecules/BlogSlider/BlogSlider';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import LoadingOverlay from 'src/components/atoms/LoadingOverlay/LoadingOverlay';
import ErrorMessage from 'src/components/atoms/ErrorMessage/ErrorMessage';
import 'src/components/templates/Blog/blog.scss';

const initPagination = {
  current: 1,
  total: 50,
  pageSize: 12,
};

const Blog: React.FC<BlogProps> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [postList, setPostList] = useState<BlogSnippetProps[]>([]);
  const [currentDisplayChunk, setCurrentDisplayChunk] = useState<
    BlogSnippetProps[][]
  >([]);
  const [featuredList, setFeaturedList] = useState([]);
  const [pagination, setPagination] = useState(initPagination);
  const [loading, setLoading] = useState(false);

  // Access the user credentials
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      void client
        .get('/api/blog/get?_format=json', {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(({ data }) => {
          // process Blogs
          const blogsArray = data.blogs;
          const chunks = [];
          setPagination((prev) => ({ ...prev, total: blogsArray.length }));
          for (let i = 0; i < blogsArray.length; i += pagination.pageSize) {
            chunks.push(blogsArray.slice(i, i + pagination.pageSize));
          }
          setPostList(chunks[0]);
          setCurrentDisplayChunk(chunks);
          setFeaturedList(data.featuredBlogs);

          setLoading(false);
        })
        .catch(() => {
          setErrorMessages(true);
          setLoading(false);
        });
    }
  }, [csrf_token, pagination.pageSize, password, username]);

  const handlePageChange = (selectedPage: number) => {
    setPagination((prev) => ({ ...prev, current: selectedPage }));
    setPostList(currentDisplayChunk[selectedPage - 1]);
  };

  return (
    <div
      className="blog"
      {...props}
    >
      <h1 className="blog__title">Blog</h1>

      {loading && <LoadingOverlay />}
      {errorMessages && <ErrorMessage />}

      {!loading && (
        <div className="blog__top">
          <BlogSlider collection={featuredList} />
        </div>
      )}

      {!loading && (
        <div className="blog__panel">
          <h2 className="blog__panelTitle">RECENT POSTS</h2>
          <BlogList collection={postList} />
        </div>
      )}

      {!loading && (
        <div className="blog__pagination">
          <Pagination
            totalCount={pagination.total}
            pageSize={pagination.pageSize}
            currentPage={pagination.current}
            setCurrentPage={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Blog;
