import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import 'src/components/atoms/LoadingOverlay/loadingOverlay.scss';

interface Props {
  clear?: boolean;
  zIndex?: number;
}

const LoadingOverlay: React.FC<Props> = ({
  clear = false,
  zIndex,
  ...props
}) => {
  // if it exists, passes the zIndex prop
  const style = zIndex !== undefined ? { '--zIndex': zIndex } : {};

  return (
    <div
      className={`loadingOverlay ${
        clear ? 'loadingOverlay--clear' : ''.trim()
      }`}
      style={style as React.CSSProperties}
      {...props}
      aria-busy="true"
      aria-live="polite"
    >
      <span
        className="loadingOverlay__icon"
        aria-hidden="true"
      >
        <FontAwesomeIcon icon={faSpinner} />
      </span>
    </div>
  );
};

export default LoadingOverlay;
