// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState, useContext } from 'react';
import { type ThemeProps } from './../../../theme/index';
import DateForm from './../../molecules/DateForm/DateForm';
import DataTables from './../../atoms/DataTables/DataTables';
import GraphHeader from './../../atoms/GraphHeader/GraphHeader';
import { GraphHeadeValues as HeaderValues } from 'src/types/reportTypes.ts';
import { Title, Border, Container } from './styles';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import moment from 'moment';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import getSubUsersCall from '../../../hook/getSubUsersCall';

interface Props extends ThemeProps {
  data?: object[];
}

const TrafficContent: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  // filter fields
  const [dateFilter, setDateFilter] = useState('7-days-past');
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, 'd').format('YYYY-MM-DD'),
  );
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [offersFilter, setOffersFilter] = useState('all');
  const [affiliates, setAffiliates] = useState([]);
  const [selectedUser, setSelectedUser] = useState('All');

  // top graph data
  const [clicks, setClicks] = useState(0);
  const [sales, setSales] = useState(0);
  const [payouts, setPayouts] = useState(0);
  const [conversions, setConversions] = useState(0);

  const [dataTables, setDataTables] = useState([]);

  const headerObj: HeaderValues = {
    clicks: Math.floor(Number(clicks)),
    sales: Number(sales),
    payouts: Number(payouts),
    conversions: Number(conversions),
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      // getSubUsersList();
      getSubUsersCall(
        password,
        username,
        csrf_token,
        setAffiliates,
        setErrorMessages,
      );
      callApi(dateFilter, fromDate, toDate, selectedUser, false, offersFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const handleFilterChange = (newValues): void => {
    setLoading(true);
    setErrorMessages('');

    setDateFilter(newValues.range.value);
    setFromDate(moment(newValues.startDate).format('YYYY-MM-DD'));
    setToDate(moment(newValues.endDate).format('YYYY-MM-DD'));
    setOffersFilter(newValues.traffic.value);

    let users = 'All';
    if (newValues.affiliates !== undefined) {
      users = newValues.affiliates.label;
    }
    setSelectedUser(users);

    callApi(
      newValues.range.value,
      moment(newValues.startDate).format('YYYY-MM-DD'),
      moment(newValues.endDate).format('YYYY-MM-DD'),
      users,
      false,
      newValues.traffic.value,
    );
  };

  const callApi = (
    dateFilter,
    fromDate,
    toDate,
    users,
    exportType = false,
    offersFilter,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client
      .post(
        '/api/reports?_format=json',
        {
          type: 'traffic_referrals_report',
          dateFilter,
          fromDate,
          toDate,
          users,
          offers: offersFilter,
          export_type: exportType ? 'file' : '',
        },
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            csrf_token,
          },
          auth: { username, password },
        },
      )
      .then(({ data }) => {
        if (exportType) {
          // 2. download the file
          const element = document.createElement('a');
          const file = new Blob([data.data], { type: 'text/plain' });
          element.href = URL.createObjectURL(file);
          element.download = data.fileName;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        } else {
          // graph header
          setClicks(data.totals.clicks);
          setSales(data.totals.sales);
          setPayouts(data.totals.payouts);
          setConversions(data.totals.conversions);
          setDataTables(data.tableData);
        }

        setLoading(false);
      })
      .catch((e) => {
        setErrorMessages('Failed to get data for the report.');

        setLoading(false);
      });
  };

  const handleExportFile = (): void => {
    setLoading(true);

    callApi(dateFilter, fromDate, toDate, selectedUser, true, offersFilter);
  };

  return (
    <Container {...props}>
      {loading && <LoadingOverlay />}

      <Title>Traffic Report</Title>

      {errorMessages !== '' ? <ErrorMessage message={errorMessages} /> : ''}

      <DateForm
        type={'traffic'}
        affiliates={affiliates}
        callback={handleFilterChange}
        exportFile={handleExportFile}
      />
      <Border>
        <GraphHeader data={headerObj} />
        <DataTables
          variant="traffic"
          data={dataTables}
        />
      </Border>
    </Container>
  );
};

export default TrafficContent;
