import React from 'react';
import { capitalize, snakeCase, replace } from 'lodash';
import { formatCurrency, formatNumber } from 'src/utils/numberFormat';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconFingerClick } from 'src/icons/iconFingerClick.svg';
import { ReactComponent as IconCart } from 'src/icons/iconCart.svg';
import { ReactComponent as IconCartAdd } from 'src/icons/iconCartAdd.svg';
import { GraphHeaderProps, GraphHeaderValues } from 'src/types/reportTypes';
import 'src/components/atoms/GraphHeader/graphHeader.scss';

const GraphHeader: React.FC<GraphHeaderProps> = ({ data, variant }) => {
  const items = Object.keys(data);

  return (
    <header
      className={`graphHeader ${
        variant ? `graphHeader--${variant}` : ''
      }`.trim()}
    >
      {items.map((item, key) => {
        if (
          data[item as keyof GraphHeaderValues] !== undefined &&
          parseFloat(String(data[item as keyof GraphHeaderValues])) < 0
        ) {
          return null;
        }

        const type = replace(item, 'locked', '').toLowerCase();
        const title = capitalize(snakeCase(item).replace('_', ' '));
        const modifierClass = item.startsWith('locked_')
          ? `locked${capitalize(item.slice(7))}`
          : item;
        const showIcon = !(type === 'sales' || type === 'payouts');

        return (
          <div
            className="graphHeader__item"
            key={`heading-${key}`}
          >
            <h3 className="graphHeader__itemTitle">{title}</h3>
            <p
              className={`graphHeader__itemAmount graphHeader__itemAmount--${modifierClass}`}
              data-type={type.toLowerCase()}
            >
              {showIcon && title === 'Clicks' && (
                <BaseIcon
                  className="graphHeader__itemAmountIcon"
                  icon={IconFingerClick}
                  size={18}
                />
              )}
              {showIcon && title === 'Orders' && (
                <BaseIcon
                  className="graphHeader__itemAmountIcon"
                  icon={IconCartAdd}
                  size={18}
                />
              )}
              {showIcon && title === 'Locked orders' && (
                <BaseIcon
                  className="graphHeader__itemAmountIcon"
                  icon={IconCart}
                  size={18}
                />
              )}
              {!showIcon && '$'}
              <span className="graphHeader__itemAmountSpan">
                {['sales', 'payouts', 'lockedSales', 'lockedPayouts'].includes(
                  item,
                )
                  ? formatCurrency(
                      parseFloat(
                        String(data[item as keyof GraphHeaderValues] ?? '0'),
                      ),
                    )
                  : formatNumber(
                      parseFloat(
                        String(data[item as keyof GraphHeaderValues] ?? '0'),
                      ),
                    )}
              </span>
            </p>
          </div>
        );
      })}
    </header>
  );
};

export default GraphHeader;
