import React, { Component, ErrorInfo } from 'react';
import {
  ErrorBoundaryProps,
  ErrorBoundaryState,
} from 'src/types/validationTypes';
import ErrorMessage from 'src/components/atoms/ErrorMessage/ErrorMessage';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.state.error?.message ? (
        <ErrorMessage message={this.state.error.message} />
      ) : (
        <ErrorMessage />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
