import React from 'react';

const Icon = (): JSX.Element => {
  return (
    <svg
      id="Icon-Facebook"
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="currentColor"
      preserveAspectRatio="xMinYMin meet"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 20.0726C40 30.3337 32.3272 38.7963 22.4244 40V26.2058H27.8322L28.9522 20.0725H22.4156V17.9036C22.4156 14.6596 23.68 13.4151 26.96 13.4151C27.9756 13.4151 28.8 13.4397 29.2722 13.4876V7.93087C28.3756 7.6822 26.1922 7.43352 24.9278 7.43352C18.2478 7.43352 15.1678 10.5972 15.1678 17.4297V20.0725H11.04V26.2069H15.1678V39.5549C6.45612 37.3856 0 29.4862 0 20.0726C0 8.98693 8.95444 0 20 0C31.0456 0 40 8.98693 40 20.0726Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
