// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useState, useEffect } from 'react';
import { styled } from './../../../theme/index';
import BrandsFilter from './../../molecules/BrandsFilter/BrandsFilter';
import SpecialPromotionsGrid from './../../organisms/SpecialPromotionsGrid/SpecialPromotionsGrid';
import Pagination from './../../molecules/Pagination/Pagination';
import { ButtonElement } from '../../atoms/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../../context/UserContext';
import client from '../../api';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

// interface Props {
//   // options: Option[]
//   // promotions: PropsCard[]
//   // pagination: {
//   //   current: number
//   //   total: number
//   //   pageSize: number
//   // }
// }

const Container = styled('div', {
  marginBottom: '20px',
});

const HeaderPromotions = styled('div', {
  display: 'initial',
  justifyContent: 'space-between',
  '@bp3': {
    display: 'flex',
  },
});

const Title = styled('h1', {
  color: '$black',
  fontSize: '20px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  marginBottom: 0,
  padding: 0,
  marginTop: 0,
  fontFamily: '$heading',
  '@bp3': {
    fontSize: '25px',
  },
});

const ContentButton = styled('div', {
  padding: '7px 0',
});

const FooterPromotions = styled('div', {
  margin: '21px 10px 0',
  padding: '17px 0',
  div: {
    justifyContent: 'center',
  },
});

const styleButton = {
  height: '44px',
  fontFamily: '$heading',
  fontSize: '$size15',
};

const styleBrandHeader = {
  width: '100%',
  marginBottom: '36px',
  padding: '20px 0',
  fontFamily: '$body',
  div: {
    fontSize: '$size15',
    fontWeight: '$regular',
  },
  '@bp3': {
    '& > div': {
      '&:last-of-type': {
        marginLeft: '23px',
      },
    },
  },
};

const stylePromotionContent = {
  div: {
    borderBottom: 'none',
    marginBottom: 0,
  },
  '.socialButtons': {
    marginTop: '12px',
  },
};

const initPagination = {
  current: 1,
  total: 24,
  pageSize: 24,
};

const SpecialPromotions: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [options, setOptions] = useState([
    {
      value: '',
      label: '- Any -',
    },
  ]);
  const [promotions, setPromotions] = useState([]);
  const [filterKeywords, setFilterKeywords] = useState(null);
  const [filterBrandTitle, setFilterBrandTitle] = useState(null);
  const [filterOrderBy, setFilterOrderBy] = useState('new');
  const [filterSortBy, setFilterSortBy] = useState('start');
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [pagination, setPagination] = useState(initPagination);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .post(
          '/api/special_promotions/list?_format=json',
          {
            page: pagination.current,
            keywords: filterKeywords,
            brandTitle: filterBrandTitle,
            orderBy: filterOrderBy,
            sortBy: filterSortBy,
          },
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          },
        )
        .then(({ data }) => {
          if (data.brands !== undefined && options.length === 1) {
            setOptions([...options, ...data.brands]);
          }
          setPromotions(data.specialPromotions);
          setPagination({ ...pagination, current: data.current });
          setPagination({ ...pagination, total: data.totalItems });
          setLoading(false);
        })
        .catch((e) => {
          // console.log("error", e);
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    password,
    username,
    csrf_token,
    pagination.current,
    filterKeywords,
    filterBrandTitle,
    filterOrderBy,
    filterSortBy,
  ]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlePageChange = (pageNumber: number) => {
    window.scrollTo(0, 0);
    pagination.current = pageNumber;
    setPagination({ ...pagination, current: pageNumber });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFilters = (newFilters) => {
    if (newFilters.searchString !== '') {
      setFilterKeywords(newFilters.searchString);
    } else {
      setFilterKeywords(null);
    }

    if (newFilters.search !== undefined) {
      setFilterBrandTitle(newFilters.search.label);
    }

    if (filterSortBy !== newFilters.sort.value) {
      setFilterSortBy(newFilters.sort.value);
    }

    if (filterOrderBy !== newFilters.order.value) {
      setFilterOrderBy(newFilters.order.value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const exportAllHandler = () => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setDownloading(true);

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .post(
          '/api/special_promotions/export/file?_format=json',
          {
            page: pagination.current,
            keywords: filterKeywords,
            brandTitle: filterBrandTitle,
            orderBy: filterOrderBy,
            sortBy: filterSortBy,
          },
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          },
        )
        .then(({ data }) => {
          if (data.includes('http://') === true) {
            data = data.replace('http', 'https');
          }

          // create a tag obj.
          const link = document.createElement('a');
          link.href = data;
          link.setAttribute('download', data);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);

          setDownloading(false);
        })
        .catch(() => {
          // console.log("error", e);
          setErrorMessages(true);
        });
    }
  };

  return (
    <Container {...props}>
      {downloading && (
        <LoadingOverlay style={{ position: 'absolute', zIndex: '100' }} />
      )}
      {errorMessages && <ErrorMessage />}

      <HeaderPromotions>
        <Title>SPECIAL PROMOTIONS</Title>

        <ContentButton>
          <ButtonElement
            type="export"
            css={styleButton}
            onClick={exportAllHandler}
          >
            <FontAwesomeIcon
              icon={faDownload}
              style={{ marginRight: '3px' }}
            />{' '}
            EXPORT ALL
          </ButtonElement>
        </ContentButton>
      </HeaderPromotions>

      <BrandsFilter
        options={options}
        css={styleBrandHeader}
        handleFilters={handleFilters}
      />

      {loading && (
        <LoadingOverlay style={{ position: 'absolute', zIndex: '100' }} />
      )}

      <SpecialPromotionsGrid
        promotions={promotions}
        css={stylePromotionContent}
      />

      <FooterPromotions>
        <Pagination
          totalCount={pagination.total}
          pageSize={pagination.pageSize}
          currentPage={pagination.current}
          setCurrentPage={(selectedPage) => {
            pagination.current = selectedPage;
            handlePageChange(selectedPage);
          }}
        />
      </FooterPromotions>
    </Container>
  );
};

export default SpecialPromotions;
