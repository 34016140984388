import React from 'react';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconX } from 'src/icons/iconSocialX.svg';
import { ReactComponent as IconFacebook } from 'src/icons/iconSocialFacebook.svg';
import { ReactComponent as IconInstagram } from 'src/icons/iconSocialInstagram.svg';
import 'src/components/organisms/Footer/footer.scss';

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <small className="footer__copyright">
        &copy;{year} BrandCycle, a{' '}
        <a
          className="footer__copyrightLink"
          href="https://www.stackcommerce.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          StackCommerce
        </a>{' '}
        company.
      </small>
      <ul className="footer__socials">
        <li className="footer__socialsItem">
          <a
            className="footer__socialsItemLink"
            href="https://www.instagram.com/brandcycle/"
            target="_blank"
            rel="noreferrer"
          >
            <BaseIcon
              icon={IconInstagram}
              size={23}
            />
          </a>
        </li>
        <li className="footer__socialsItem">
          <a
            className="footer__socialsItemLink"
            href="https://www.facebook.com/BrandCycleInc/"
            target="_blank"
            rel="noreferrer"
          >
            <BaseIcon
              icon={IconFacebook}
              size={23}
            />
          </a>
        </li>
        <li className="footer__socialsItem">
          <a
            className="footer__socialsItemLink"
            href="https://x.com/brandcycle"
            target="_blank"
            rel="noreferrer"
          >
            <BaseIcon
              icon={IconX}
              size={23}
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
