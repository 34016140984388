import { useMemo } from 'react';
import {
  UsePaginationProps,
  PaginationItem,
} from 'src/types/baseComponentTypes';

const usePagination = ({
  totalCount,
  pageSize,
  currentPage,
  maxVisiblePages = 7,
}: UsePaginationProps): [PaginationItem[], number] => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // if total pages is within maxVisiblePages, return them all
    if (totalPageCount <= maxVisiblePages) {
      return {
        pages: Array.from({ length: totalPageCount }, (_, i) => i + 1),
        totalPageCount,
      };
    }

    const pages: PaginationItem[] = [];
    const isEven = maxVisiblePages % 2 === 0;
    // subtract 2 from maxVisiblePages to account for first/last pages (or dots)
    const siblingsCount = Math.floor((maxVisiblePages - 2) / 2);

    let start: number;
    let end: number;

    // near start
    if (currentPage <= siblingsCount + 2) {
      start = 1;
      end = maxVisiblePages - 2; // so we get [1,2,3,4,5] then 'dots' => last
    }
    // near end
    else if (currentPage >= totalPageCount - siblingsCount - 1) {
      start = totalPageCount - (maxVisiblePages - 2) + 1;
      end = totalPageCount;
    }
    // middle range
    else {
      start = currentPage - siblingsCount;
      end = currentPage + siblingsCount;
      if (isEven) {
        end += 1; // adjust for even maxVisiblePages (not working correctly)
      }
    }

    // always push first page if start > 1
    if (start > 1) {
      pages.push(1);
      if (start > 2) {
        pages.push('dots');
      }
    }

    for (let i = start; i <= end; i++) {
      // only push pages within valid range
      if (i >= 1 && i <= totalPageCount) {
        pages.push(i);
      }
    }

    // always add last page if end < totalPageCount
    if (end < totalPageCount) {
      if (end < totalPageCount - 1) {
        pages.push('dots');
      }
      pages.push(totalPageCount);
    }

    return { pages, totalPageCount };
  }, [totalCount, pageSize, currentPage, maxVisiblePages]);

  return [paginationRange.pages, paginationRange.totalPageCount];
};

export default usePagination;
